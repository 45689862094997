import React from "react";

import LandingLayout from "../components/layouts/LandingLayout";

import AboutUs from "../components/sections/AboutUs";
import Contacts from "../components/sections/Contacts";
import FAQ from "../components/sections/FAQ";
import Feedback from "../components/sections/Feedback";
import Services from "../components/sections/Services";
import Slider from "../components/sections/Slider";

export default function Landing() {
  return ( <LandingLayout>
    <>
      <Slider />
      <Services />
      <Feedback/>
      <AboutUs/>
      <FAQ />
      <Contacts />
    </>
    </LandingLayout>
  );
}
