import React from "react"

import { Flex } from "@chakra-ui/react"

import Footer from "../sections/Footer";

const LandingLayout = ( {children, ...rest} : any ) => {

  return (
    <Flex
      direction="column"
      align="center"
      m="0 auto"
      bg="#ffffff"
      overflowX="hidden"
      {...rest}
    >
      {children}
      <Footer />
    </Flex>
  )
};

export default LandingLayout;