import React, { useState } from "react";

import { useSpring, animated, config } from 'react-spring'

import {
  Flex,
  Image,
  Heading,
  Box,
  Text,
  Link,
  Button,
  SimpleGrid,
  Wrap,
  WrapItem,
  VStack
} from "@chakra-ui/react"

import { services } from "../../store/services";

import { Icon } from "@chakra-ui/react"

import Logo from "../ui/Logo";

import { BiPhoneCall } from "react-icons/bi";

import map from "lodash/map";

import { AiFillNotification, AiOutlinePhone, AiOutlineSafetyCertificate } from "react-icons/ai";

import Move from "../ui/IconAnimations";

const Service = ({ thumbnail, title, desc, ...rest } : any ) => {

  const [hover, setHover] = useState(false);
  const hoverState = useSpring({
    transform: hover
      ? 'translate3d(0px, -12px, 0px) scale(1.03)'
      : 'translate3d(0px, 0px, 0px) scale(1)',
    config: config.wobbly
  });

  return (

      <Box
        align="center"
        margin="0px auto"
        {...rest}
      >

        <animated.div
          style={{
            width: '100%',
            ...hoverState
          }}
          onMouseEnter={() => setHover(true)}
          onMouseLeave={() => setHover(false)}
        >

          <Image
            src={thumbnail}
          />

        </animated.div>

        <Heading
          as="h4"
          fontSize="17px"
          color="#000000"
          textTransform="uppercase"
          m="25px 0px"
          lineHeight="1.5"
        >
          {title}
        </Heading>

        <Text
          m="15px 0px"
          color="#8a8a8a"
          fontSize={ { xxl: "14px", xl: "14px", xs: "14px", lg: "12px", md: "12px" } }
        >
          {desc}
        </Text>

        <Link
          href="#feedback"
          bg="#ff0000"
          p="9px 3px"
          borderRadius="5px"
          color="#ffffff"
        >

          <Icon
            as={BiPhoneCall}
            w="40px"
            h="33px"
            borderRadius="3px"
            bg="#ffffff"
            color="#ff0000"
            m={{ xxl: "-0.5px 0px 3px", xl: "-0.5px 0px 3px", xs: "-0.5px 0px 3px", lg: "-0.5px 0px 3px", md: "-0.1px 0px 3px", sm: "-0.1px 0px 3px" }}
            p="7px"
          />

          <Button
            bg="none"
            fontSize="12px"
            p="0px 30px"
            className="service-button"
            mt="-3px"
            _hover={{
              bg: "none"
            }}
          >
              ПОЛУЧИТЬ КОНСУЛЬТАЦИЮ
          </Button>

        </Link>

      </Box>

  )
};

export default function Services() {
  return (
    <>

      <Flex
        maxW={{ xl: "1200px" }}
        w="100%"
        pos="relative"
        m="50px 0px"
        id="services"
      >

        <Heading
          as="h2"
          w="100%"
          textAlign="center"
          fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
          textTransform={ { xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "uppercase", sm: "uppercase" } }
        >

          <Icon as={AiOutlineSafetyCertificate}
                bg="#1f191a"
                color="#ffffff"
                borderRadius="5px"
                p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
                m={ { xxl: "0px 0px 8px", xl: "0px 0px 8px", xs: "0px 0px 8px", lg: "0px 0px 8px", md: "0px 0px 4px", sm: "0px 0px 4px" } }
          />

          Полный спектр услуг в сфере недвижимости

          <Text
            fontSize="14px"
            fontFamily="Century Gothic"
          >
            Выберите интересующую вас услугу
            и получите бесплатную консультацию по любым вопросам
          </Text>

        </Heading>

      </Flex>

      <Flex
        align="center"
        maxW={{ xl: "1200px" }}
        w="100%"
        mb={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "25px", sm: "25px" }}
      >

        <SimpleGrid
          columns={{ xxl: 3, xl: 3, xs: 3, lg: 3, md: 1, sm: 1 }}
          spacingX="40px"
          spacingY="20px"
          alignItems="center"
          w="100%"
        >

          {map(services, (service: any) => (

            <Service
              thumbnail={service.thumbnail}
              title={service.title}
              desc={service.desc}
            />

          ))}

        </SimpleGrid>

      </Flex>

      <Flex
        align="center"
        bg="URL('./images/call-center.png')"
        w="100%"
        bgRepeat="no-repeat"
        p="50px 0px"
        mt={50}
        display={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "none", sm: "none" }}
        >

          <VStack
            align="center"
            maxW={{ xl: "1200px" }}
            w="100%"
            m="0px auto"
          >

            <Heading>
              И многие другие вопросы интересующие вас
            </Heading>

            <Wrap
              spacing={3}
            >

              <WrapItem
                mt="15px"
              >
                <Logo
                  w="250px"
                />
              </WrapItem>

              <WrapItem>

                <Link
                  href="tel:+998951440060"
                  fontSize={{ xxl: "40px", xl: "40px", xs: "30px" }}
                  color="#ff0000"
                  fontFamily="Century Gothic Bold"
                  mt="10px"
                >
                  <Move
                    x={20}
                  >
                    <Icon
                      as={AiOutlinePhone}
                      w="57px"
                      h="57px"
                      fill="#ffffff"
                      bg="#ff0000"
                      p="5px 3px"
                      borderRadius="50%"
                      m="-4px 25px 0px 0px"
                    />
                  </Move>
                  +998 (95) 144-00-60
                </Link>

              </WrapItem>

              <WrapItem>
                <Link
                  href="tel:+998946771234"
                  color="#ff0000"
                  fontSize={{ xxl: "40px", xl: "40px", xs: "30px" }}
                  fontFamily="Century Gothic Bold"
                  mt="10px"
                >

                  +998 (94) 677-12-34
                </Link>
              </WrapItem>

            </Wrap>

          </VStack>

      </Flex>

    </>
  );
}
