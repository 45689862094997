import { extendTheme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools"

const breakpoints = createBreakpoints({
  sm : "320px",
  md : "768px",
  lg : "991px",
  xs : "1024",
  xl : "1300px",
  xxl: "1400px",
});

//1300

// Version 2: Using functions
const customTheme = extendTheme({
  styles: {
    global: (props) => ({
      body: {
        color: "#000000",
        fontFamily: "Century Gothic !important",
        bgSize: "contain",
        bgRepeat: "no-repeat",
        lineHeight: "base"
      },
      h1: {
        fontFamily: "Century Gothic Bold"
      },
      h2: {
        fontFamily: "Century Gothic Bold"
      },
      h4: {
        fontFamily: "Century Gothic Bold"
      }
    })
  },
  breakpoints
} );

export default customTheme;