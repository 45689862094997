import React from "react";
import { Box, Image } from "@chakra-ui/react";

import logo from "../../assets/images/logo.png";

const Logo = ( ( props : any ) => {

  return (
    <Box {...props}>
      <Image src={logo} />
    </Box>
  );

} );

export default Logo;
