import React from "react";

import {
  Flex,
  Heading,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  Box,
  Badge
} from "@chakra-ui/react"

import { Icon } from "@chakra-ui/react"

import {
  AiOutlineQuestionCircle
} from "react-icons/ai";

const Item = ({ title, desc, ...rest } : any ) => {

  return(
    <AccordionItem
      border="0px">
      <h2>
        <AccordionButton
          _focus={{ boxShadow: "none" }}
        >
          <AccordionIcon
            color="#ffffff"
            bg="#ff0000"
            borderRadius="50%"
            mr={15}
            fontSize={32}
          />
          <Box flex="1" textAlign="left">
            {title}
          </Box>
        </AccordionButton>
      </h2>
      <AccordionPanel pb={4}>
        <Badge>Ответ: </Badge> {desc}
      </AccordionPanel>
    </AccordionItem>
  )

};

export default function FAQ() {
  return (
    <>

      <Flex
        id="faq"
        maxW={{ xl: "1200px" }}
        w="100%"
        pos="relative"
        m="50px 0px"
      >

        <Heading
          as="h2"
          w="100%"
          textAlign="center"
          fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
        >
          <Icon as={AiOutlineQuestionCircle}
            bg="#1f191a"
            color="#ffffff"
            borderRadius="5px"
            p={ { xxl: "5px", xl: "5px", xs: "3px 5px", lg: "3px 5px", md: "3px 5px", sm: "3px 5px" } }
            m={ { xxl: "0px 0px 8px", xl: "0px 0px 8px", xs: "0px 0px 8px", lg: "0px 0px 8px", md: "0px 0px 4px", sm: "0px 0px 4px" } }
          />
          Вопрос - Ответ
        </Heading>

      </Flex>

      <Flex
        align="center"
        maxW={{ xl: "1200px" }}
        w="100%"
        mb={75}
        p={{ xxl: "0px", xl: "0px 50px", xs: "0px 50px" }}
      >

        <Accordion
          allowToggle
          w="100%"
        >

          <Item
            title="Что выгоднее - обратиться сразу в несколько агенств или заключить договор с одним?"
            desc="На первый взгляд,кажется, что чем больше агентов будут продавать вашу квартиру, тем лучше. На самом деле экслюзивный договор, когда продавец
              обязуется в другие агенства не обращаться и не продавать самостоятельно, выгоднее. Если квартира попадает в базу десятков агенств, риэлторам нет резона слишком стараться ее продать - ведь можно вложить деньги в рекламу, а квартиру продадут другие. К тому же возможны ситуации, когда одни агенты начнут путаться под ногами у других и в итоге срывать сделку. Когда договор экслюзивный, риэлтор стараеться на все 100 процентов, потому что от результата напрямую зависят его комиссионные"
          />

          <Item
            title="Вы гарантируете максимальную цену, или будете ее снижать постепенно?"
            desc=""
          />

          <Item
            title="Думаю, самому продать квартиру или отдать на продажу в агентство. Сколько процентов возьмете?"
            desc=""
          />

          <Item
            title="Решил продать квартиру. В договоре пришлось занизить стоимость. Чем я рискую?"
            desc=""
          />

          <Item
            title="Продаю дом. Он находится в залоге у банка (ипотека). Реально ли найти на него покупателя?"
            desc=""
          />

        </Accordion>

      </Flex>
    </>
  );
}
