import { useForm } from "react-hook-form";

import React, { useState, useReducer } from "react";

import { useSpring, animated, config } from 'react-spring'

import emailjs from 'emailjs-com';

import set from "lodash/set";

import {
  Flex,
  Heading,
  Box,
  Text,
  Button,
  Stack,
  List,
  ListItem,
  Badge,
  FormControl,
  Input,
  Textarea,
  FormErrorMessage
} from "@chakra-ui/react"

const Step = ({ step, desc, badge, last, ...rest } : any ) => {

  const [hover, setHover] = useState(false);

  const hoverState = useSpring({
    from:{
      opacity: '0'
    },
    to:{
      opacity: hover ? '1' : '0',
      width: hover ? '100%' : '0%',
      backgroundColor: hover ? '#ff0000' : '#ff5c5c'
    },
    config:{
      duration: 400
    }
  });

  return (

    <ListItem
      p="20px 0px"
      textAlign="left"
      align="left"
    >

      <Text>
        <Badge
          className="vertical-orientation"
          bg="none"
          fontSize="16px"
          fontWeight="bold"
          mt="-4px"
          color="#ff0000"
        >
          {step}
        </Badge>

        { last
          ? desc
          : <animated.span style={{ position: "relative" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Badge bg="none" fontSize="16px" textTransform="inherit" fontWeight="bold" verticalAlign="initial" m="0" p="0px 5px 0px 0px">
              {badge}
            </Badge>
            <animated.span style={hoverState} className="text-underline">
              ____
            </animated.span>
          </animated.span>
        }{ last
          ? <animated.span style={{ position: "relative" }} onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}>
            <Badge bg="none" fontSize="16px" textTransform="inherit" fontWeight="bold" verticalAlign="initial" m="0" p="0px 0px 0px 5px">
              {badge}
            </Badge>
            <animated.span style={hoverState} className="text-underline">
              ____
            </animated.span>
          </animated.span>
          : desc
        }

      </Text>

    </ListItem>

  );

};

const initialState = initialValues();

function reducer(state: any, action: any) {
  return set({ ...state }, action.key, action.value);
}

export default function Feedback() {

  const { handleSubmit, register, formState } = useForm();

  const [state, dispatch] = useReducer(reducer, initialState);

  function onSubmit( values : any ) {

    return new Promise(resolve => {

      emailjs.send('service_2v4voyt', 'template_e5oj6n8', state, 'user_58GXs5lGUr2W9TOViAc55')
        .then((result) => {
          resolve( formState.isSubmitting );
        }, (error) => {
          resolve( formState.isSubmitting );
        } );
    });

  }

  const handleChange = (key: string) => (e: any) => {

    dispatch({
      key,
      value: e.target.value,
    });
  };

  return (

    <Flex
      id="feedback"
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      bg="URL('./images/feedback.jpg')"
      bgRepeat="no-repeat"
      bgSize="cover"
      w="100%"
      p={{ xxl: "45px 0px", xl: "45px 50px", xs: "45px 50px", lg: "45px 50px", md: "0px 40px 25px", sm: "0px 40px 25px" }}
    >

      <Stack
        maxW={{ xl: "1200px" }}
        direction={["column", "row"]}
        w="100%"
        m="0px auto"
        display="block"
      >

        <Box
          w={{ xxl: "40%", xl: "40%", xs: "40%", lg: "40%", md: "100%", sm: "100%" }}
          mr={{ xxl: "19%", xl: "19%", xs: "19%", lg: "19%", md: "0px", sm: "0px" }}
          float="left"
         >

          <Heading
            as="h2"
            w="100%"
            textAlign="center"
            textTransform="uppercase"
            m="40px 0px"
            fontSize={{ xxl: "32px", xl: "32px", xs: "32px", lg: "32px", md: "24px", sm: "24px" }}
          >
            Риэлтор компании <br /> ООО "TVOY DOM"
          </Heading>

          <List spacing={3}>

              <Step
                step="01"
                desc="Информация о состоянии рынка"
                badge="анализ и предложения."
                last={1}
              />

            <Step
              step="02"
              desc="на объект недвижимости."
              badge="Проверка документов"
              last={0}
            />

            <Step
              step="03"
              desc="объектов недвижимости."
              badge="Проведение просмотров"
              last={0}
            />

            <Step
              step="04"
              desc="и дальнейшее оформление у нотариуса."
              badge="Организация внесение задатка"
              last={0}
            />

            <Step
              step="05"
              desc="на всех этапах сделки."
              badge="Комфорт и поддержка"
              last={0}
            />

          </List>

        </Box>

        <Box
          w={{ xxl: "40%", xl: "40%", xs: "40%", lg: "40%", md: "100%", sm: "100%" }}
          textAlign="center"
          p={{ xxl: "50px", xl: "50px", xs: "50px", lg: "50px", md: "24px", sm: "24px" }}
          shadow="md"
          borderWidth="1px"
          borderRadius="15px"
          bg="#ffffff"
          float="left"
        >

          <Heading
            as="h4"
            w="100%"
            textAlign="center"
            textTransform="uppercase"
            fontSize="24px"
            mb="30px"
          >
            Заполните форму заявки
            прямо сейчас!
          </Heading>

          {formState.isSubmitted
            ? <Box
              bg="#ff0000"
              color="#ffffff"
              textTransform="uppercase"
              p={5}
              fontSize={12}
              fontWeight="bold"
              >
              Ваша заявка отправленна
            </Box>
            : ''
          }

          <form onSubmit={handleSubmit( onSubmit )} id="feed-back-form">

            <FormControl
              mb="25px"
              id="name"
              isRequired >

              <Input
                h="55px"
                bg="#f5f5f5"
                border="none"
                placeholder="Введите ваше имя"
                name="name"
                value={state.name}
                onChange={handleChange("name")}
                required
              />

              <FormErrorMessage>

              </FormErrorMessage>

            </FormControl>

            <FormControl
              mb="25px"
              id="phone"
              isRequired>

              <Input
                h="55px"
                bg="#f5f5f5"
                border="none"
                placeholder="Введите ваш номер телефона"
                name="phone"
                value={state.phone}
                onChange={handleChange("phone")}
                required
              />

              <FormErrorMessage>

              </FormErrorMessage>

            </FormControl>

            <FormControl
              mb="25px"
              id="message"
              isRequired>

              <Textarea
                h="150px"
                bg="#f5f5f5"
                border="none"
                placeholder="Текст сообщения"
                name="message"
                value={state.message}
                onChange={handleChange("message")}
                required
              />

              <FormErrorMessage>

              </FormErrorMessage>

            </FormControl>

            <Button
              color="#ff0000"
              border="2px solid #ff0000"
              p={{ xxl: "30px 50px", xl: "30px 50px", xs: "30px 50px", lg: "30px 50px", md: "0px 3px", sm: "0px 3px" }}
              bg="none"
              fontFamily="Century Gothic Bold"
              mt={4}
              isLoading={formState.isSubmitting}
              fontSize={{ xxl: "inherit", xl: "inherit", xs: "inherit", lg: "inherit", md: "14px", sm: "14px" }}
              type="submit"
            >
              КОНСУЛЬТАЦИЯ СПЕЦИАЛИСТА
            </Button>

          </form>

        </Box>

      </Stack>

    </Flex>
  );
}

function initialValues(): any {
  return {
    name: '',
    phone: '',
    message: ''
  };
}
