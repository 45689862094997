import React from "react";

import { Icon } from "@chakra-ui/react"
import {
  AiFillFacebook, AiFillInstagram
} from "react-icons/ai";

import {
  Flex,
  Heading,
  Box,
  Badge,
  Link,
  List,
  ListItem,
  Text
} from "@chakra-ui/react";

import GMapLocationSelector from '../ui/Maps';

import Move from "../ui/IconAnimations";

export default function Contacts() {


  return (

    <Flex
      id="contacts"
      w="100%"
      pos="relative"
    >

      <Box
        pos="absolute"
        zIndex={9999}
        mt={50}
        maxW={{ xl: "1200px" }}
        top={0}
        left={{ xxl: "50%", xl: "50%" }}
        ml={{ xxl: "-580px", xl: "-530px", xs: "75px", lg: "20px" }}
      >

        <Heading
          as="h4"
          textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "left", md: "center", sm: "center" }}
          lineHeight="1.5"
          maxW={{ xxl: "54%", xl: "54%", xs: "63%", lg: "63%" }}
          p={{ xxl: "0px", xl: "0px", xs: "0px", lg: "0px", md: "0px 90px 35px" }}
          color="#35384b"
          fontSize={{ xxl: "36px", xl: "36px", xs: "36px", lg: "36px", md: "24px", sm: "24px" }}
        >
          Приглашаем вас на чашечку
          кофе ежедневно с 09:00 до 19:00
        </Heading>

        <List
          shadow="md"
          borderWidth="1px"
          borderRadius="15px"
          bg="#ffffff"
          textAlign={{ xxl: "left", xl: "left", xs: "left", lg: "left", md: "center", sm: "center" }}
          p={25}
          w={{ xxl: "400px", xl: "400px", xs: "400px", lg: "400px", md: "65%" }}
          m={{ xxl: "30px 0px 0px", xl: "30px 0px 0px", xs: "30px 0px 0px", lg: "30px 0px 0px", md: "0px auto" }}
        >

          <ListItem
            p="10px 0px"
          >

            <Heading
             as="h4"
             fontSize={20}
             textTransform="uppercase"
            >
              Наши контакты:
            </Heading>

          </ListItem>

          <ListItem
            p="10px 0px">
            <Link
              href="tel:+998951440060"
              color="#ff0000"
              fontSize={20}
              fontWeight="bold"
            >
              +998 (95) 144-00-60
            </Link>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#8e8e8e"
                p="5px 0px"
                bg="none"
              >
                АДРЕС ГОЛОВНОГО ОФИСА:
              </Badge>
              г. Ташкент, Мирзо Улугбекский район,
              улица Буюк Ипак Йули д. 152
            </Text>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#8e8e8e"
                p="5px 0px"
                bg="none"
              >
                АДРЕС ОФИСА:
              </Badge>
              г. Алмалык
            </Text>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#8e8e8e"
                p="5px 0px"
                bg="none"
              >
                ЭЛЕКТРОННАЯ ПОЧТА:
              </Badge>
              realtor7777777@gmail.com
            </Text>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Text
              lineHeight={1.5}
            >
              <Badge
                w="100%"
                color="#8e8e8e"
                p="5px 0px"
                bg="none"
              >
                ГРАФИК РАБОТЫ:
              </Badge>
              Пн-Пт с 09:00 до 19:00
            </Text>
          </ListItem>

          <ListItem
            p="10px 0px">
            <Link href="https://www.facebook.com/agenstvo.mesterdom" target="__blank">
              <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                <Icon
                  as={AiFillFacebook}
                  color="#3d589b"
                  w="35px"
                  h="35px"
                />
              </Move>
            </Link>
            <Link href="https://www.instagram.com/misterdom.uz?r=nametag" target="__blank">
              <Move scale={1.5} springConfig={{ mass: 2, tension: 100, friction: 20 }} >
                <Icon
                  as={AiFillInstagram}
                  color="#c22984"
                  w="35px"
                  h="35px"
                />
              </Move>
            </Link>

          </ListItem>

        </List>

      </Box>

      <GMapLocationSelector
        lat={41.3270142}
        lng={69.3384092}
      />

    </Flex>
  );
}