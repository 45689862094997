import consulting from '../assets/images/consulting.png';
import overpay from '../assets/images/overpay.png';
import stagnation from '../assets/images/stagnation.png';
import sales from '../assets/images/sales.png';
import investment from '../assets/images/investment.png';
import typography from '../assets/images/typography.png';
import extraction from '../assets/images/extraction.png';
import apartment from '../assets/images/apartment.png';
import depts from '../assets/images/depts.png';


export const services = [ {
  thumbnail: consulting,
  title    : 'Бесплатные консультации по вопросам недвижимости',
  desc     : ''
}, {
  thumbnail: overpay,
  title    : 'Как купить и не переплачивать',
  desc     : ''
}, {
  thumbnail: stagnation,
  title    : 'Как дорого продать недвижимость даже в период застоя',
  desc     : ''
}, {
  thumbnail: sales,
  title    : 'Вопросы связанные',
  desc     : 'с продажей, покупкой, аренды, сложной документации ( суд, приватизация, наследство) вашей недвижимости.'
}, {
  thumbnail: investment,
  title    : 'Как грамотно инвестировать',
  desc     : 'в недвижимость с минимальными рисками (новострой или вторичку).'
}, {
  thumbnail: typography,
  title    : 'Оформление соглашения о задатке',
  desc     : 'за квартиру а также доведение до нотариального заверения (ошибки , риски).'
},{
  thumbnail: extraction,
  title    : 'Как выписать бывшего владельца',
  desc     : 'и прописанных жильцов в купленной вами квартиры'
},{
  thumbnail: apartment,
  title    : 'Как определить качество дома',
  desc     : 'строения, ремонт, покупая недвижимость.'
},{
  thumbnail: depts,
  title    : 'Как купить недвижимость без долгов',
  desc     : '(нотариус такой гарантии не даёт!)'
} ];